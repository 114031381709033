body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.containerCss {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.header,
.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
}

.header {
  border-bottom: 2px solid #fff;
}

.footer {
  border-top: 2px solid #fff;
  margin-top: auto;
}

.sidebar {
  background-color: #f8f8f8;
  padding: 20px;
  border-right: 2px solid #ddd;
}

.sidebar a {
  display: block;
  color: #333;
  text-decoration: none;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
}

.sidebar a:hover {
  background-color: #ddd;
}

.content {
  flex: 1;
  padding: 20px;
}

h1,
h2 {
  color: #333;
}

code {
  background-color: #f4f4f4;
  padding: 2px 4px;
  border-radius: 4px;
}

pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

/*Start table style*/

.table-container {
  padding-top: 20px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

@media (max-width: 1300px) {
  .table-container {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .table-container {
    font-size: 10px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

th {
  background-color: #f2f2f2;
}

table th:nth-child(1),
table td:nth-child(1) {
  width: 15%;
}

table th:nth-child(2),
table td:nth-child(2) {
  width: 15%;
}

table th:nth-child(3),
table td:nth-child(3) {
  width: auto;
}

table th:nth-child(4),
table td:nth-child(4) {
  width: 15%;
}

@media (max-width: 1150px) {
  table th:nth-child(4),
  table td:nth-child(4) {
    width: 25%;
  }
}

table th:nth-child(5),
table td:nth-child(5) {
  width: 15%;
}

/*End table style*/

.container-text-table {
  max-width: 1200px;
}